(function ($, Drupal) {
  'use strict';

  /**
   * Main Menu Subnav Formatter v1
   */
  var mainMenuSubnavFormatterV1 = {
    formatter: $('.mm-subnav-formatter-v1')
  };

  Drupal.behaviors.mainMenuSubnavFormatterV1 = {
    get: function (key) {
      return mainMenuSubnavFormatterV1[key];
    },

    set: function (key, val) {
      mainMenuSubnavFormatterV1[key] = val;
    },

    attach: function (context, settings) {
      var self = this;
      var formatter = self.get('formatter');

      formatter.each(function () {
        var thisFormatter = $(this);

        $(window).on('gnav::initDropdowns', function () {
          var items = thisFormatter.find('.site-navigation__item');

          if (typeof items !== 'undefined') {
            items.each(function () {
              if ($(this).hasClass('is-submenu')) {
                $(this).find('.site-navigation__link').first().once().click(function () {
                  return false;
                });
              }
            });
          }
        });
      });
    }
  };
})(jQuery, Drupal);
